.ourSocials {
  position: relative;
  line-height: 2.604166666666667vh;
  padding-right: var(--padding-12xsH) var(--padding-12xsW);
}
.twitterIcon {
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.iconsSocialTwitter {
  width: 2.2222222222222223vw;
  border-radius: var(--br-5xs);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xsH) var(--padding-6xsW);
}
.mdiinstagramIcon {
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.iconsSocialInstagram {
  width: 2.2222222222222223vw;
  border-radius: var(--br-5xs);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xsH) var(--padding-6xsW);
}
.ourSocialsParent,
.socialIcons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.ourSocialsParent {
  flex-direction: column;
}
.icbaselineWhatsappIcon {
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.iconsSocialInstagram1 {
  width: 2.2222222222222223vw;
  border-radius: var(--br-5xs);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xsH) var(--padding-6xsW);
}
.socialsArea {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  text-align: left;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
}
