.filterButtonComps {
  box-sizing: border-box;
  max-width: 100%;
}
.filterButtonComps {
  flex: 1;
  padding: 0 0 0;
  min-width: 30.208333333333332vw;
}
.sideNavigationB {
  box-sizing: border-box;
  max-width: 100%;
}
.sideNavigationB {
  width: 22.916666666666664vw;
  padding: 87.58333333333334vh 0 0;
}
.googleplayframe {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}
.googleplayframe {
  width: 7.5vw;
  flex-direction: column;
  justify-content: flex-start;
  padding: 82.89583333333334vh 0 0;
}