.currentBalance,
.currentBalance1 {
  position: relative;
  line-height: 2.604166666666667vh;
}
.currentBalance1 {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: var(--body-main);
  font-size: var(--body-main-size);
  background-color: transparent;
  height: 2.604166666666667vh;
  flex: 1;
  color: var(--colors-green);
  text-align: left;
  display: inline-block;
  min-width: 11.805555555555555vw;
}
.currentBalanceParent,
.fRAME {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.currentBalanceParent {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--colors-grey-bg);
  flex-wrap: wrap;
  padding: var(--padding-5xsH) var(--padding-xsW);
  gap: var(--gap-9xs);
}
.fRAME {
  align-self: stretch;
  padding: 0 0 var(--padding-5xsH);
}
.amount {
  position: relative;
  line-height: 2.604166666666667vh;
}
.text,
.textfieldsmall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.text {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 2.604166666666667vh;
  font-family: var(--body-main);
  font-size: var(--body-main-size);
  color: var(--colors-dark-1);
  padding: 0;
}
.textfieldsmall {
  flex: 1;
  border-radius: var(--corner-radius-m);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  padding: var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW);
  min-width: 3.3333333333333335vw;
}
.allLanguages {
  flex: 1;
  position: relative;
  line-height: 2.604166666666667vh;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.smalldropdownarrowIcon {
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  position: relative;
}
.customLine,
.filterbuttoncomps {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filterbuttoncomps {
  height: 3.6458333333333335vh;
  width: 6.944444444444445vw;
  border-radius: var(--corner-radius-m);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-blue2);
  box-sizing: border-box;
  justify-content: flex-start;
  padding: 0 var(--padding-smiW) 0 var(--padding-2xsW);
  gap: var(--gap-5xs);
}
.customLine {
  align-self: stretch;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--gap-9xs);
  font-size: var(--body-main-size);
  color: var(--colors-purple-light);
}
.customLineTextFieldSmall,
.inputAmoutArea {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.customLineTextFieldSmall {
  align-items: flex-start;
  gap: var(--gap-9xs);
}
.inputAmoutArea {
  align-items: center;
  font-size: var(--caption-cap-12-size);
}
.lineActiveFrame,
.lineActiveFrame1 {
  position: relative;
  line-height: 2.604166666666667vh;
}
.radioButtonPaymentMethodAr {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.buttonRange {
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  position: relative;
  display: none;
}
.lineActive,
.lineActive1,
.lineFont {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.26041666666666663vh;
}
.lineFont {
  border-radius: var(--br-10xs);
  background-color: var(--colors-grey-stroke);
  width: 27.77777777777778vw;
  display: none;
}
.lineActive,
.lineActive1 {
  border-radius: var(--br-11xs);
  background-color: var(--color-mediumseagreen-100);
  width: 4.861111111111112vw;
}
.lineActive {
  top: 0.9114583333333334vh;
  left: 0.1388888888888889vw;
  border-radius: var(--br-10xs);
  background-color: var(--colors-grey-stroke);
  width: 27.7777778vw;
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 0.26041666666666663vh;
  border-radius: var(--br-10xs);
  cursor: pointer;
  outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  border-radius: 50%;
  border-width: 0;
  background: url('/public/button-range.svg') no-repeat center / contain;
  cursor: pointer;
}
.slider.dark::-moz-range-thumb {
  background: url('/public/button-range-dark.svg') no-repeat center / contain !IMPORTANT;
}
.slider.dark::-webkit-slider-thumb {
  background: url('/public/button-range-dark.svg') no-repeat center / contain !IMPORTANT;
}
.slider::-moz-range-thumb {
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  border-radius: 50%;
  border-width: 0;
  background: url('/public/button-range.svg') no-repeat center / contain;
  cursor: pointer;
}
.buttonRangeIcon {
  position: absolute;
  top: 0;
  left: 4.444444444444445vw;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  z-index: 1;
}
.buttonsFrame {
  height: 2.083333333333333vh;
  flex: 1;
  position: relative;
}
.buttons,
.sliderArea {
  display: flex;
  flex-direction: row;
}
.buttons {
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.sliderArea {
  align-self: stretch;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0;
}
.radioButtonPaymentMethodArParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--caption-cap-12-size);
}
.discountCode {
  width: 5.763888888888889vw;
  border: 0;
  outline: 0;
  font-family: var(--body-main);
  font-size: var(--caption-cap-12-size);
  background-color: transparent;
  height: 2.604166666666667vh;
  position: relative;
  line-height: 2.604166666666667vh;
  color: var(--colors-dark-1);
  text-align: left;
  display: inline-block;
}
.text1,
.tutorCode {
  align-self: stretch;
  display: none;
}
.tutorCode {
  width: 3.3333333333333335vw;
  position: relative;
  line-height: 2.604166666666667vh;
}
.text1 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.fRAMEParent,
.inputAmoutArea1,
.textfieldsmall1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.textfieldsmall1 {
  flex: 1;
  border-radius: var(--corner-radius-m);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-9xsH) var(--padding-5xsW);
  &:focus-within {
    border-color: var(--colors-darkerblue);
  }
}
.fRAMEParent,
.inputAmoutArea1 {
  flex-direction: column;
  align-items: flex-start;
}
.inputAmoutArea1 {
  height: 6.770833333333333vh;
  gap: var(--gap-9xs);
  color: var(--colors-dark-3);
}
.fRAMEParent {
  padding: 0 var(--padding-10xsW);
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--body-main-size);
  color: var(--colors-dark-1);
  font-family: var(--body-main);
}
