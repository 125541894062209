.downloadApp {
  align-self: stretch;
  position: relative;
  line-height: 2.604166666666667vh;
}
.appStoreEng,
.googlePlayEng {
  align-self: stretch;
  height: 4.166666666666666vh;
  position: relative;
  border-radius: var(--corner-radius-m);
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.download {
  width: 7.5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  text-align: left;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
}
