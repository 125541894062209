.paymentForm,
.pleaseEnterYour {
  position: relative;
  font-family: var(--body-main);
  color: var(--colors-dark-1);
  text-align: left;
}
.paymentForm {
  margin: 0;
  align-self: stretch;
  font-size: var(--header-header-1-size);
  line-height: 4.166666666666666vh;
  font-weight: 600;
}
.pleaseEnterYour {
  font-size: var(--body-main-size);
  line-height: 2.604166666666667vh;
}
.paymentFormParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-10xsW);
  gap: var(--gap-9xs);
}
.currentBalance,
.div {
  position: relative;
  font-size: var(--body-main-size);
  line-height: 2.604166666666667vh;
  font-family: var(--body-main);
  color: var(--colors-dark-1);
  text-align: left;
}
.div {
  height: 2.604166666666667vh;
  flex: 1;
  color: var(--colors-green);
  display: inline-block;
  min-width: 10.555555555555555vw;
}
.currentBalanceParent {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--colors-grey-bg);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-5xsH) var(--padding-xsW);
  gap: var(--gap-9xs);
}
.addBalance,
.saveSession {
  width: 25.833333333333336vw;
  position: relative;
  font-size: var(--header-subtitle-size);
  font-weight: 500;
  font-family: var(--body-main);
  color: var(--colors-dark-3);
  text-align: left;
  display: none;
}
.saveSession {
  width: 26.111111111111114vw;
  font-size: var(--body-main-size);
  line-height: 2.604166666666667vh;
  color: var(--colors-btn-txt);
  text-align: center;
  display: inline-block;
}
.buttonsecondary {
  cursor: pointer;
  border: 0;
  padding: 0.78125vh;
  background-color: var(--colors-darkerblue);
  align-self: stretch;
  border-radius: var(--corner-radius-l);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.actionscontainer {
  margin: 0;
  width: 31.11111111111111vw;
  border-radius: var(--br-base);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xlH) var(--padding-xlW);
  gap: var(--gap-5xl);
  height: min-content;
}
